import React from 'react';
import * as styles from './Teams.module.scss';
// import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import TeamsSocials from './TeamsSocials';

const Teams = (props) => {
    return (
        <div className={styles.teams}>
            {props.teamMembers.map((team) => {
                return (
                    <div className={styles.item}>
                        <div className={styles.imageContainer}>
                            <GatsbyImage
                                image={getImage(team.imgUrl)}
                                alt={team.name}
                                imgStyle={{ borderRadius: '10px' }}
                                className={styles.image}
                            />
                        </div>
                        <div className={styles.names}>
                            <p>{team.name}</p>
                            <span>{team.role}</span>

                            <div className={styles.tooltip}>
                                <a href="#">
                                    <div className={styles.wrapper}>
                                        <TeamsSocials
                                            social={team.social1}
                                            className={styles.social1}
                                        />
                                    </div>
                                </a>
                                <a href="#">
                                    <div className={styles.wrapper}>
                                        <TeamsSocials
                                            social={team.social2}
                                            className={styles.social2}
                                        />
                                    </div>
                                </a>
                                <a href="#">
                                    <div
                                        className={styles.wrapper}
                                        id={styles.lastSocial}
                                    >
                                        <TeamsSocials
                                            social={team.social3}
                                            className={styles.social3}
                                        />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Teams;
