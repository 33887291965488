import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import * as styles from './team.module.scss';
import Teams from '../components/Teams';

const Team = ({ data }) => {
    const [screen, setScreen] = useState(null);
    const [hasRan, setHasRan] = useState(false);
    const teamsData = data.allTeamsJson.nodes;
    const mentorsData = data.allMentorsJson.nodes;

    const getScreenSize = () => {
        const width = window.innerWidth;

        if (width >= 1300) {
            return 'xl';
        } else if (width >= 992 && width < 1300) {
            return 'large';
        } else if (width >= 600 && width < 992) {
            return 'small';
        } else {
            return 'xs';
        }
    };

    const handleResize = () => {
        setScreen(getScreenSize());
    };

    const updateScreenSize = () => {
        setScreen(getScreenSize());
    };

    useEffect(() => {
        if (!hasRan) {
            setHasRan(true);
            updateScreenSize();
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getScreenSize]);

    return (
        <div>
            <Layout darkBg={false}>
                <div className={styles.container}>
                    <h5>Meet the Team</h5>

                    {screen === 'xs' ? null : (
                        <p className={styles.subtext}>
                            Our team consists of experienced individuals that
                            are passionate about bridging the digital divide.
                            Our management team has more than 30 years of
                            combined experiences in technology, education and
                            community services.
                        </p>
                    )}

                    <div className={styles.teamsContainer}>
                        <Teams teamMembers={teamsData} />
                    </div>

                    <h5>Meet Your Mentors</h5>

                    {screen === 'xs' ? null : (
                        <p className={styles.subtext}>
                            Our mentors are some of the best experts and
                            instructional designers in the tech industry, with
                            knowledge spanning across different topics,
                            industries and backgrounds such as venture capital,
                            software engineering, product development, data
                            science & entrepreneurship.
                        </p>
                    )}

                    <div className={styles.teamsContainer}>
                        <Teams teamMembers={mentorsData} />
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Team;

export const TeamsQuery = graphql`
    query {
        allTeamsJson {
            nodes {
                id
                name
                role
                social1
                social1Url
                social2
                social2Url
                social3
                social3Url
                imgUrl {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
            }
        }
        allMentorsJson {
            nodes {
                id
                name
                role
                social1
                social1Url
                social2
                social2Url
                social3
                social3Url
                imgUrl {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
            }
        }
    }
`;
