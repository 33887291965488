import React from 'react'
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn"

const TeamsSocials = (props) => {
    if (props.social === 'facebook') {
        return ( <FaFacebookF {...props} /> )
    } else if (props.social === 'twitter') {
        return ( <FaTwitter {...props} /> )
    } else if (props.social === 'linkedin') {
        return ( <FaLinkedinIn {...props} /> )
    } else return null
}

export default TeamsSocials
