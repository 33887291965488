// extracted by mini-css-extract-plugin
export var teams = "Teams-module--teams--1non3";
export var item = "Teams-module--item--2gxMY";
export var imageContainer = "Teams-module--imageContainer--1PQv4";
export var image = "Teams-module--image--2czSy";
export var names = "Teams-module--names--262uW";
export var tooltip = "Teams-module--tooltip--23lJu";
export var social1 = "Teams-module--social1--2Gqtp";
export var social2 = "Teams-module--social2--3e8ob";
export var social3 = "Teams-module--social3--uoZFZ";
export var fadeIn = "Teams-module--fadeIn--226KN";
export var bounce = "Teams-module--bounce--1IUzU";
export var description = "Teams-module--description--uHEQD";
export var slideIn = "Teams-module--slideIn--1gRkX";
export var wrapper = "Teams-module--wrapper--1o8U4";
export var lastSocial = "Teams-module--lastSocial--gztfj";